@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  scroll-behavior: smooth;
  
}
@layer base {
  :root {
 

    --slate-50: 210 40% 98%;
    --slate-100: 210 40% 96%;
    --slate-200: 214 32% 91%;
    --slate-300: 213 27% 84%;
    --slate-400: 215 20% 65%;
    --slate-500: 215 16% 47%;
    --slate-600: 215 19% 35%;
    --slate-700: 215 25% 27%;
    --slate-800: 217 33% 17%;
    --slate-900: 222 47% 11%;
    --slate-950: 229 84% 5%;


    --background: 230 30% 98%;
    --foreground: 240 10% 3.9%;
    --card: 230 30% 98%;
    --card-foreground: 240 10% 3.9%;
    --popover: 230 30% 98%;
    --popover-foreground: 240 10% 3.9%;
    --contrast: 0 0% 100%;
    --contrast-foreground: 0 0% 0%;


    
    

    --primary: 240 90% 67%;
    --primary-50: 240 100% 97%;
    --primary-100: 240 95% 93%;
    --primary-200: 240 96% 87%;
    --primary-300: 240 97% 78%;
    --primary-400: 240 98% 68%;
    --primary-500: 240 90% 67%;
    --primary-600: 243 83% 63%;
    --primary-700: 246 76% 58%;
    --primary-800: 249 67% 50%;
    --primary-900: 252 61% 43%;
    --primary-950: 255 57% 31%;
/* 
    --primary-50: 228 107% 97%;
    --primary-100: 227 107% 94%;
    --primary-200: 230 103% 89%;
    --primary-300: 231 101% 82%;
    --primary-400: 236 100% 74%;
    --primary-500: 240 90% 67%;
    --primary-600: 245 82% 59%;
    --primary-700: 246 72% 51%;
    --primary-800: 245 63% 41%;
    --primary-900: 244 56% 34%;
    --primary-950: 242 56% 21%; */

    --background: 210 30% 98%;
    --foreground: 240 10% 3.9%;
    --card: 230 30% 98%;
    --card-foreground: 240 10% 3.9%;
    --popover: 230 30% 98%;
    --popover-foreground: 240 10% 3.9%;
    --contrast: 0 0% 100%;
    --contrast-foreground: 0 0% 0%;
    --primary: 240 90% 67%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 20% 92.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 40%;
    --accent: 240 20% 92.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 20% 90%;
    --input: 240 20% 94%;
    --ring: 237 97%, 85%;    
    --radius: 0.5rem;
    --warning: 38 92% 50%;
    --warning-foreground: 48 96% 89%;
    


/* 
    --primary-foreground: 0 0% 98%;
    --secondary: 240 20% 92.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 40%;
    --accent: 240 20% 92.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 20% 90%;
    --input: 240 20% 94%;

    --ring: 237 97%, 85%;    
    --radius: 0.5rem;
    --warning: 38 92% 50%;
    --warning-foreground: 48 96% 89%;
    --color-start: #2e2e2f;
    --color-end: #222222; */
  }

  .dark {

    --slate-50: 210 40% 2%;
    --slate-100: 210 40% 4%;
    --slate-200: 214 32% 9%;
    --slate-300: 213 27% 16%;
    --slate-400: 215 20% 35%;
    --slate-500: 215 16% 53%;
    --slate-600: 215 19% 65%;
    --slate-700: 215 25% 73%;
    --slate-800: 217 33% 83%;
    --slate-900: 222 47% 89%;
    --slate-950: 229 84% 95%;

    --gray-100: 0 0% 10%;
    --gray-200: 0 0% 12%;
    --gray-300: 0 0% 16%;
    --gray-400: 0 0% 18%;
    --gray-500: 0 0% 27%;
    --gray-600: 0 0% 53%;
    --gray-700: 0 0% 56%;
    --gray-800: 0 0% 49%;
    --gray-900: 0 0% 63%;
    --gray-1000: 0 0% 93%;


    --background: 240 10% 4.9%;
    /* --background: 230 30% 4%; */
    --contrast: 0 0% 0%;

    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;

    --primary: 240 90% 67%;
    --primary-dark: 240° 90% 42%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    /* --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%; */
    --border: 0 0% 12%;
    --input: 0 0% 10%;
    --ring: 240 4.9% 83.9%;
    --warning: 48 96% 89%;
    --warning-foreground: 38 92% 50%;
    --color-start: #cfcfcf;
    --color-end: #4c3ba1;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer components {
  .zoom-image [data-rmiz-modal-overlay="visible"] {
    @apply bg-background/10 backdrop-blur;
  }

  .zoom-image [data-rmiz-modal-img] {
    @apply rounded-md lg:rounded-2xl;
  }
}

@media (max-width: 640px) {
  .container {
    @apply px-4;
  }
}

.radial-gradient {
  background: radial-gradient(
    128.88% 128.88% at 103.9% -10.39%,
    #e84d70 0%,
    #a337f6 53.09%,
    #28a7ed 100%
  );
}

.animate-opacity {
  animation: opacityCycle 1s infinite alternate;
}

@keyframes opacityCycle {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes smoothColorChange {
  0% {
    background-position: 200% center;
  }
  100% {
    background-position: -200% center;
  }
}

.smooth-color-change {
  background-size: 200% 100%;
  background-image: linear-gradient(
    to right,
    var(--color-start),
    var(--color-end),
    var(--color-start),
    var(--color-end)
  );
  animation: smoothColorChange 30s infinite linear;
}



/* Typography */
.h1 {
  @apply text-5xl font-extrabold;
  /* @apply text-5xl font-extralight; */
}

.h2 {
  @apply text-4xl font-extrabold;
}

.h3 {
  @apply text-3xl font-extrabold;
}

.h4 {
  @apply text-2xl font-extrabold;
}

@screen md {
  .h1 {
      @apply text-6xl;
      /* @apply text-7xl;
      @apply uppercase;
      letter-spacing: 0.2em;
      line-height: 1.2;
      font-weight: 300;
      font-size: 60px; */
  }

  .h2 {
      @apply text-5xl;
  }

  .h3 {
      @apply text-4xl;
  }
}


.new-button_button--primary {
 

  background: radial-gradient(231.94% 231.94% at 50% 100%, 
  hsl(var(--primary-400)) 0, hsl(var(--primary-900) / 0%) 25.24%), 
  linear-gradient(180deg, hsl(var(--contrast-foreground) / 0%), hsl(var(--contrast-foreground) / 4%)), hsl(var(--primary-900) / 1%);

  box-shadow: 0 0 0 0 hsl(var(--primary-100) / 40%) , 0 2px 5px 0 
  hsl(var(--primary-100) / 39%), 0 8px 8px 0 hsl(var(--primary-100) / 34%), 0 19px 11px 0 
  hsl(var(--primary-100) / 20%), 0 34px 14px 0 hsl(var(--primary-100) / 6%), 0 53px 15px 
  0 hsl(var(--primary-100) / 1%), inset 0 0 12px 0 hsl(var(--contrast-foreground) / 8%), inset 0 -8px 32px 0 hsl(var(--primary-50));
  padding: 8px 17px;
  border-radius: 10px;
  position: relative;
  color: hsl(var(--accent-foreground));  
  text-align: center;
  line-height: 20px;
  font-weight: 500;
  z-index: 1;

 
}
.btn,
.btn-sm {
  @apply text-sm font-medium inline-flex items-center justify-center border border-transparent rounded-full whitespace-nowrap transition duration-150 ease-in-out;
}

.btn {
  @apply px-5 py-2 rounded-[10px];
}

.btn-sm {
  @apply px-3 py-1;
}

.font-effect-hero {
  -webkit-text-stroke: 0.4px #FFFFFF05;
  -webkit-text-fill-color: transparent;
   /* text-shadow: 1px 1px 0 hsla(0,0%,100%,.2), 1px 1px 0 rgba(0,0,0,1);  */
   text-shadow: 1px 1px 0 hsl(0, 0%, 0%, 0.05), -1px -1px 0 rgba(255,255,255,0.1); 
}



@keyframes hero-text-slide-up-fade {
  0% {
      /* opacity: 0; */
      /* transform: translateY(16px); */
      -webkit-transform: translate(0, 16px);
      -moz-transform: translate(0, 16px);
      -ms-transform: translate(0, 16px);
  }

  to {
      /* opacity: 1; */
      /* transform: translateY(0) */
      -webkit-transform: translate(0, 0px);
      -moz-transform: translate(0, 0px);
      -ms-transform: translate(0, 0px);
  }
}

.animate-hero-text-slide-up-fade {
  animation: hero-text-slide-up-fade 1s ease-in-out
}

@keyframes webgl-scale-in-fade {
  0% {
      opacity: 0;
      transform: scale(.7)
  }

  to {
      opacity: 1;
      transform: scale(1)
  }
}

.animate-webgl-scale-in-fade {
  animation: webgl-scale-in-fade 1s ease-in-out;
}

@property --border-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: '<angle>';
}
